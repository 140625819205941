// ==========================================================================
// Google Calendar Card Styles
// ==========================================================================
#calendarPanel > div {
    a {
        color: lighten(@color-primary, 35%);
    }
}
#calendarPanel > div, .sidebar, .whats-new {
    ul.ccms-gcalendar-calendar-feed-list {
        li {
            border-left: 0 solid;
            border-right: 0 solid;
            border-top: 0 solid;
            margin: 0 0 6px;
            padding: 5px;
            .ccms-gcalendar-dateWrapper {
                border: 1px solid @color-text;
                height: 45px;
                margin: 0 5% 0 0 !important;
                overflow: hidden;
                width: 45px !important;
            }
            .ccms-gcalendar-day {
                display: none;
            }
            .ccms-gcalendar-month {
                background: @color-primary none repeat scroll 0 0;
                font-size: 80%;
                color: #fff;
            }
            .ccms-gcalendar-date {
                color: contrast(#ffffff, #727272, @color-primary);
                font-size: 130%;
            }
            article.ccms-gcalendar-details {
                color: @color-text;
                .ccms-gcalendar-title {
                    color: @color-primary;
                }
                .ccms-gcalendar-description {
                    height: 4.2em;
                    overflow: hidden;
                }
            }
            &.ccms-gcalendar-full {
                background: @color-primary none repeat scroll 0 0;
                border: medium none !important;
                color: #fff;
                margin: 20px 0 !important;
                min-height: 20px;
                &:hover {
                    text-decoration: underline;
                    background: @color-primary none repeat scroll 0 0;
                }
            }
        }
    }
}