// ==========================================================================
// Catapult Banners
// ==========================================================================

.ccms-banner-slider{
    .slick-prev,.slick-next{color:#fff;}
}

.ccms-banner h1 {
    color: #fff;
    line-height: 1.5em;
    white-space: normal;
    overflow: inherit;
    text-overflow: ellipsis;
}

.slick-slider {height:@banner-height;}

.ccms-banner-overlay {text-shadow:2px 0px 3px rgba(0,0,0,.5), -2px -0px 3px rgba(0,0,0,.5), 0px 2px 3px rgba(0,0,0,.5), 0px -2px 3px rgba(0,0,0,.5);}

.ccms-banner-slider .slick-dots {bottom: 10px; color:#ffffff;}